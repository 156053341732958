import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"
import Sponsors from "../components/sponsors"
const ContactPage = () => (
  <Layout>
    <SEO title="THACO | Event Sponsorship" name="sponsors" />
    <Header page="contact" />
    <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="mb-5 text-center">
          <span className="bg-dark px-2 py-1">ผู้สนับสนุน</span>
        </h2>
        <p className="text-white mt-3">
          <Sponsors />
        </p>
        <p className="text-center my-1">
          สนใจเป็นผู้ร่วมสนับสนุนหรือเป็นพันธมิตรกับพวกเรา?
        </p>
        <p className="text-center my-1">
          ท่านสามารถติดต่อเราได้ที่{" "}
          <a
            className="pb-0 sans"
            href="https://www.facebook.com/ThailandComputingOlympiad"
          >
            Facebook Page
          </a>
        </p>
        <p className="text-center">
          หรืออีเมล{" "}
          <a href="mailto:contact@thaco.tech" className="pb-0 sans">
            contact@thaco.tech
          </a>
        </p>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default ContactPage
