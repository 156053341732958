import React from "react"
import { Link } from "gatsby"
export const Sponsors = () => (
  <div className='row mt-4 sponsors'>
    <div className='mb-5 col-md-6 text-center'>
    <a className='h-100' href='https://beta.programming.in.th'>
        <img
          className='sponsor-img'
          src={require("../assets/images/programming-in-th-black.svg")}
          alt="Programming.in.th"
        />
      </a>
    </div>
    <div className='mb-5 col-md-6 text-center'>
      <a className='h-100' href='https://digitalocean.com'>
        <img
          className='sponsor-img'
          src={require("../assets/images/DO_Logo_horizontal_white.svg")}
          alt="Digitalocean.com"
        />
      </a>
    </div>
  </div>
)
export default Sponsors
